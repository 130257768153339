import React, { useState } from "react"
import { Link } from "gatsby"
import { Div, Icon } from "atomize"

import CardHeader from "../common/CardHeader"
// import Button from "../common/molecules/BlueButton"
import BlueButton from "../common/molecules/BlueButton"
import PortableText from "../common/PortableText"
import {
  getVariantFeatureImage,
  getVariantHoverImage,
} from "../../utilities/helpers"
import ImageLazyLoad from "../common/molecules/ImageLazyLoad"

export default function ProductCard({
                                      variant,
                                      index,
                                      isFeatureAvailable,
                                      onClickAddToCartButton,
                                    }) {
  const [loading, setLoading] = useState(false)

  const handleMouseEnter = () => {
    const card = document.getElementById(`variant-img-${variant._id}`)
    card.style["opacity"] = 0
    const hoverCard = document.getElementById(`variant-hover-${variant._id}`)
    hoverCard.style["opacity"] = 1
  }
  const handleMouseLeave = () => {
    const card = document.getElementById(`variant-img-${variant._id}`)
    card.style["opacity"] = 1
    const hoverCard = document.getElementById(`variant-hover-${variant._id}`)
    hoverCard.style["opacity"] = 0
  }

  return (
    <Div
      rounded="lg"
      border="1px solid"
      borderColor="lightPink"
      shadow="1"
      className="card-item"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Link
        to={`/products/${
          variant.productHandle
        }/${variant.variantTitle.toLowerCase()}`}
        style={{ color: "inherit" }}
      >
        {/* Card Header for Desktop */}
        <CardHeader
          heading={variant.title}
          padding="0.625rem 0.625rem"
          className="card-item-header"
        />
        {/* Card Header for Mobile */}
        <CardHeader
          heading={variant.mobileTitle || variant.title}
          padding="0.625rem 0.625rem"
          className="card-item-header-mob"
        />
        <Div bg="yellow" rounded={{ b: "lg" }} d="flex" flexDir="column">
          <Div
            p="0.5rem 1.25rem"
            textAlign="center"
            bg="#ffffff"
            className="card-item-sub-header"
          >
            {variant.caption ? (
              <PortableText blocks={variant.caption} />
            ) : (
              <>&nbsp;</>
            )}
          </Div>
          <Div
            bg="gray"
            p="1.25rem"
            className="card-item-img-container"
            pos="relative"
          >
            {/* <Div
              id={`variant-img-${variant._id}`}
              bgImg={getVariantFeatureImage(variant)}
              p={{ b: "100%" }}
              bgSize="cover"
              bgPos="center"
              transition
            /> */}

            <ImageLazyLoad
              id={`variant-img-${variant._id}`}
              bgImg={getVariantFeatureImage(variant)}
              bgPos="center"
              bgSize="cover"
              p={{ b: "100%" }}
              transition
            />

            <Div
              bg="gray"
              p="1.25rem"
              className="card-item-img-container"
              id={`variant-hover-${variant._id}`}
              transition
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                opacity: 0,
              }}
            >
              <Div
                bgImg={getVariantHoverImage(variant)}
                p={{ b: "100%" }}
                bgSize="cover"
                bgPos="center"
              />
            </Div>
          </Div>
          {isFeatureAvailable ? (
            <BlueButton
              m="2rem auto"
              text="Add to bag"
              // maxW="9.5rem"
              className="small-font"
              w="80%"
              onClick={e => {
                e.preventDefault()
                setLoading(true)
                onClickAddToCartButton(variant, setLoading)
              }}
              disabled={loading}
              prefix={
                <Icon
                  name="Loading"
                  pos="absolute"
                  top="50%"
                  left="8.5%"
                  transform="translateY(-50%)"
                  size="25px"
                  color="white"
                  className="load-icon"
                  style={{
                    zIndex: 1,
                    opacity: loading ? 1 : 0,
                  }}
                  m={{ r: "0.5rem" }}
                />
              }
            />
          ) : (
            <BlueButton m="2rem auto" text="Shop" maxW="9.5rem" w="100%" />
          )}
        </Div>
      </Link>
    </Div>
  )
}
