import React, { useState, useEffect } from "react"
import { Div, Text, Row, Col } from "atomize"
// import posthog from "posthog-js"
import { get } from "lodash"
import ls from "local-storage"
import { useMutation, useQuery } from "@apollo/client"

import ProductCard from "../components/home/ProductCard"
import VariantCard from "../components/home/VariantCard"
import PortableText from "../components/common/PortableText"
import WebsiteContainer from "../components/common/atoms/WebsiteContainer"
import {
  FETCH_CUSTOMER,
  TOGGLE_CART,
  CHECKOUT_LINE_ITEMS_ADD,
  GET_RECHARGE_CHECKOUT,
  UPDATE_RECHARGE_CHECKOUT,
} from "../graphql/queries"
import {
  addItemToCheckout,
  addItemToRechargeCheckout,
} from "../services/cartService"

export default function ModuleContentWithProductCard({
                                                       title,
                                                       body,
                                                       products,
                                                       variants,
                                                       isHomepage,
                                                       isPcp,
                                                       checkoutData,
                                                       checkoutCreate,
                                                     }) {
  const [isFeatureAvailable, setIsFeatureAvailable] = useState(false)

  const [toggleCart] = useMutation(TOGGLE_CART, {
    onCompleted: () => {},
  })

  const { data: fetchCustomerData, loading: fetchCustomerLoading } = useQuery(
    FETCH_CUSTOMER,
    {
      skip: !ls("accessToken"),
      variables: { accessToken: ls("accessToken") },
    }
  )

  // const { data: checkoutData, refetch: refechCheckoutData } = useQuery(
  //   CHECKOUT_BY_ID,
  //   {
  //     // skip: !ls("lastCheckoutID"),
  //     variables: { id: ls("lastCheckoutID") },
  //   }
  // )

  const { data: rechargeCheckoutData, refetch: refetchRechargeCart } = useQuery(
    GET_RECHARGE_CHECKOUT,
    {
      skip: !ls("lastRechargeCheckoutID"),
    }
  )

  const [updateRechargeCheckout] = useMutation(UPDATE_RECHARGE_CHECKOUT)
  // const [checkoutCreate] = useMutation(CHECKOUT_CREATE, {
  //   onCompleted: result => {
  //     //window.history.pushState("header", "Not Pot", "/#cart")
  //     toggleCart({ variables: { cartDrawerVisible: true } })
  //     saveCheckoutInLocalStorage(result?.checkoutCreate?.checkout)
  //     refechCheckoutData({
  //       // skip: !ls("lastCheckoutID"),
  //       variables: { id: ls("lastCheckoutID") },
  //     })
  //   },
  // })

  const [checkoutLineItemsAdd] = useMutation(CHECKOUT_LINE_ITEMS_ADD, {
    onCompleted: result => {
      // window.history.pushState("header", "Not Pot", "/#Cart")
      toggleCart({ variables: { cartDrawerVisible: true } })
    },
  })

  const customer = get(fetchCustomerData, "customer")

  const onClickAddToCartButton = (variant, callback) => {
    if (
      get(rechargeCheckoutData, "rechargeCheckout.completed_at", undefined) ===
      null
    ) {
      addItemToRechargeCheckout({
        variant,
        email: get(customer, "email", undefined),
        quantity: 1,
        frequency: undefined,
        checkout: checkoutData?.node,
        rechargeCheckout: rechargeCheckoutData?.rechargeCheckout,
        refetchCart: refetchRechargeCart,
        updateRechargeCheckout,
        toggleRechargeCart: toggleCart,
        setUpdatingRechargeCheckout: callback,
      })
    } else {
      addItemToCheckout({
        checkout: checkoutData?.node,
        checkoutCreate,
        checkoutLineItemsAdd,
        variant,
        quantity: 1,
        email: get(customer, "email", undefined),
      })
      setTimeout(() => callback(false), 2000)
    }
  }

  // const getFlag = () => {
  //   const flag = posthog.isFeatureEnabled("add-to-bag")
  //   setIsFeatureAvailable(flag)
  // }

  useEffect(() => {
    // posthog.init("1v5yjATiVqFL71uaXLBd2A6LHkyrt2GaA-1c7liz9k8", {
    //   persistence: "memory",
    //   loaded: function (posthog) {
    //     posthog.reloadFeatureFlags()
    //     posthog.onFeatureFlags(getFlag)
    //   },
    // })

    // posthog.onFeatureFlags(getFlag)
  }, [])

  const isTitleOrBody = title || body

  return (
    <Div
      // p={{
      //   t: {
      //     xs: isPcp ? "1.25rem" : "6.25rem",
      //     lg: isPcp ? "2.5rem" : "9.375rem",
      //   },
      // }}
      className="bgblocks module-product-card"
      // data-emergence="hidden"
      data-bg="#fee3e1"
    >
      <WebsiteContainer>
        <Row>
          <Col
            size={{ xs: "12", lg: isHomepage ? "8" : "12" }}
            offset={{ xs: "0", lg: isHomepage ? "2" : "0" }}
          >
            {isTitleOrBody && (
              <Row
                textAlign="center"
                m={{ b: { xs: "1.25rem", lg: "2rem" } }}
                p={{
                  t: {
                    xs: "6.25rem",
                    lg: "9.375rem",
                  },
                }}
                className="module-product-card-header"
              >
                <Col
                  size={{ xs: "12", lg: isPcp ? "8" : "12" }}
                  offset={{ xs: "0", lg: isPcp ? "2" : "0" }}
                >
                  {title && (
                    <Text
                      textSize="display1"
                      textTransform="uppercase"
                      fontFamily="secondary"
                    >
                      {title}
                    </Text>
                  )}
                  {body && (
                    <Div
                      textSize={{ xs: "header", lg: "title1" }}
                      m={{ t: "1.25rem" }}
                      className="description"
                    >
                      <PortableText blocks={body} />
                    </Div>
                  )}
                </Col>
              </Row>
            )}
            <Div textAlign="center">
              <Div>
                {isHomepage || isPcp ? (
                  <Row
                    className={
                      isHomepage
                        ? "product-variant-container-home"
                        : "product-variant-container-pcp-pdp"
                    }
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {variants &&
                    variants.map((variant, index) => {
                      return (
                        <Col
                          size={{
                            xs: "6",
                            lg: isHomepage ? "6" : "4",
                          }}
                          key={`product-card-${variant._id}`}
                        >
                          <Div
                            m={{
                              t: { xs: "1.25rem", lg: "3rem" },
                            }}
                            className="product-variant-mob"
                          >
                            <VariantCard
                              variant={variant}
                              index={index}
                              isFeatureAvailable={isFeatureAvailable}
                              onClickAddToCartButton={onClickAddToCartButton}
                            />
                          </Div>
                        </Col>
                      )
                    })}
                    {products.map((product, index) => {
                      return (
                        <Col
                          size={{
                            xs: "6",
                            lg: isHomepage ? "6" : "4",
                          }}
                          key={`product-card-${product._id}`}
                        >
                          <Div
                            m={{
                              t: { xs: "1.25rem", lg: "3rem" },
                            }}
                            className="product-variant-mob"
                          >
                            <ProductCard
                              product={product}
                              index={index}
                              isFeatureAvailable={isFeatureAvailable}
                              onClickAddToCartButton={onClickAddToCartButton}
                            />
                          </Div>
                        </Col>
                      )
                    })}
                  </Row>
                ) : (
                  <Row
                    className={
                      isHomepage
                        ? "product-variant-container-home"
                        : "product-variant-container-pcp-pdp"
                    }
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {products.map((product, index) => {
                      return (
                        <Col
                          size={{
                            xs: "6",
                            lg: isHomepage ? "6" : "4",
                          }}
                          key={`product-card-${product._id}`}
                        >
                          <Div
                            m={{
                              t: { xs: "1.25rem", lg: "3rem" },
                            }}
                            className="product-variant-mob"
                          >
                            <ProductCard
                              product={product}
                              index={index}
                              isFeatureAvailable={isFeatureAvailable}
                              onClickAddToCartButton={onClickAddToCartButton}
                            />
                          </Div>
                        </Col>
                      )
                    })}
                    {variants &&
                    variants.map((variant, index) => {
                      return (
                        <Col
                          size={{
                            xs: "6",
                            lg: isHomepage ? "6" : "4",
                          }}
                          key={`product-card-${variant._id}`}
                        >
                          <Div
                            m={{
                              t: { xs: "1.25rem", lg: "3rem" },
                            }}
                            className="product-variant-mob"
                          >
                            <VariantCard
                              variant={variant}
                              index={index}
                              isFeatureAvailable={isFeatureAvailable}
                              onClickAddToCartButton={onClickAddToCartButton}
                            />
                          </Div>
                        </Col>
                      )
                    })}
                  </Row>
                )}
              </Div>
            </Div>
          </Col>
        </Row>
      </WebsiteContainer>
    </Div>
  )
}
